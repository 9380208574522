import React, { memo } from 'react';

import {
  BlockType,
  BlockCopyStyle,
  BlockBenefitStyle,
  BlockMediaCollectionStyle,
  BlockContentStyle,
  BlockImageStyle,
  BlockLogosStyle,
} from '../enums/blocks';

import {
  PageFragment,
  ContentFragment,
  BlogPostFragment,
} from '../schemas/api';

import HomeSplashRequirements from './HomeSplashRequirements';
import HomeSplashVideo from './HomeSplashVideo';
import HomeSplashVideoZoom from './HomeSplashVideoZoom';
import HomeIntegration from './HomeIntegration';
import VersionControlChart from './VersionControlChart';
import BenefitsIcons from './BenefitsIcons';

import ContentTwoColumn from './ContentTwoColumn';
import ContentOneColumn from './ContentOneColumn';
import BlockAlternatingFeatures from './BlockAlternatingFeatures';
import BlockAlternatingSmallImages from './BlockAlternatingSmallImages';
import BlockImageFullscreen from './BlockImageFullscreen';
import BlockImageGrid from './BlockImageGrid';
import BlockImageGridLargeHeading from './BlockImageGridLargeHeading';
import CareersBenefits from './CareersBenefits';
import CareersOpenings from './CareersOpenings';
import CareersMembers from './CareersMembers';
import GraphNetwork from './GraphNetwork';
import CtaBlock from './CTABlock';
import CtaDarkBlock from './CTADarkBlock';
import BlockTextTypewriter from './BlockTextTypewriter';
import ValuesWithIcons from './ValuesWithIcons';
import ValuesAlternate from './ValuesAlternate';
import Quote from './Quote';
import BlockScrollingFeatures from './BlockScrollingFeatures';
import BlockLocations from './BlockLocations';
import BlockWithWithoutFlow from './BlockWithWithoutFlow';
import BlockStatsCounter from './BlockStatsCounter';
import BlockFaq from './BlockFaq';
import BlockLogoAndTestimonials from './BlockLogoAndTestimonials';
import BlockTableCompetitors from './BlockTableCompetitors';
import BlockPreFooter from './BlockPreFooter';
import BlockTeamCarousel from './BlockTeamCarousel';
import BlockAlternatingFeaturesAnimated from './BlockAlternatingFeaturesAnimated';
import BlockPricing from './BlockPricing';
import BlockDemoForm from './BlockDemoForm';

import type { Job } from '../utils/jobs';
import BlockAi from './BlockAi';
import BlockLogo from './BlockLogo';
import HandbookCollection from './HandbookCollection';
import BlogCollection from './BlogCollection';
import BlockZoomCarousel from './BlockZoomCarousel';
import BlockHorizontalScrollCarousel from './BlockHorizontalScrollCarousel';
import BlockTestimonialCarousel from './BlockTestimonialCarousel';
import MediaCollectionBlockLogos from './MediaCollectionBlockLogos';
import BlockLogoGrid from './BlockLogoGrid';
import HomeSplashVideoOnSide from './HomeSplashVideoOnSide';
import MediaCollectionBlockTwoColumnsSide from './MediaCollectionBlockTwoColumnsSide';
import BlockIntegrations from './BlockIntegrations';

const generateCopy = (list: ContentFragment[]): Record<string, string> => {
  const mapping = {};
  list.forEach((c) => {
    mapping[c.key] = c.longValue || c.value;
  });
  return mapping;
};

type BlocksProps = {
  jobs?: Job[];
  posts?: BlogPostFragment[];
  page: PageFragment;
};

function Blocks({ jobs, page, posts = [] }: BlocksProps) {
  return (
    <>
      {page.blocks.items.map((block, index) => {
        if (!block) {
          console.warn('Empty block in block list for this page');
          return null;
        }

        const blockType = block.__typename; // eslint-disable-line
        const key = `${'sys' in block ? block.sys?.id : ''}-${index}`;

        switch (blockType) {
          case BlockType.BLOCK_SPLASH_VIDEO:
            switch (block.style) {
              case 'Zoom':
                return <HomeSplashVideoZoom data={block} key={key} />;
              case 'Video On Side':
                return <HomeSplashVideoOnSide data={block} key={key} />;
              case 'Default':
              default:
                return <HomeSplashVideo data={block} key={key} />;
            }

          case BlockType.BLOCK_COPY:
            switch (block.type) {
              case BlockCopyStyle.REQ_SPLASH:
                return (
                  <HomeSplashRequirements
                    copy={generateCopy(block.copy.items)}
                    key={key}
                  />
                );
              case BlockCopyStyle.GRAPH_NETWORK:
                return (
                  <GraphNetwork
                    copy={generateCopy(block.copy.items)}
                    key={key}
                  />
                );
              case BlockCopyStyle.VERSION_CONTROL_CHART:
                return (
                  <VersionControlChart
                    copy={generateCopy(block.copy.items)}
                    key={key}
                  />
                );
              default:
                return (
                  <div key={key}>
                    Can not find logo block for style {block.type}
                  </div>
                );
            }

          case BlockType.BLOCK_LOGO:
            switch (block.style) {
              case BlockLogosStyle.INTEGRATIONS:
                return (
                  <HomeIntegration data={block} key={`${key}-${block.style}`} />
                );
              case BlockLogosStyle.GRID:
                return (
                  <BlockLogoGrid data={block} key={`${key}-${block.style}`} />
                );
              case BlockLogosStyle.LOGOS:
              default:
                return <BlockLogo data={block} key={`${key}-${block.style}`} />;
            }
          case BlockType.BLOCK_TEAM_MEMBER:
            return <CareersMembers data={block} key={key} />;
          case BlockType.BLOCK_BENEFIT:
            switch (block.style) {
              case BlockBenefitStyle.TEAM_PAGE:
                return <CareersBenefits data={block} key={key} />;
              case BlockBenefitStyle.ICONS:
                return <BenefitsIcons data={block} key={key} />;
              default:
                return (
                  <div key={key}>
                    Can not find benefit block for style {block.style}
                  </div>
                );
            }
          case BlockType.BLOCK_BLOG_POST:
            switch (block.type.toLowerCase()) {
              case 'handbook':
                return <HandbookCollection data={block} />;
              case 'blog':
              default:
                return <BlogCollection data={block} posts={posts} />;
            }
          case BlockType.BLOCK_MEDIA_COLLECTION:
            switch (block.style) {
              case BlockMediaCollectionStyle.ICONS:
                return <ValuesWithIcons data={block} key={key} />;
              case BlockMediaCollectionStyle.LOGOS:
                return <MediaCollectionBlockLogos data={block} key={key} />;
              case BlockMediaCollectionStyle.FEATURES:
                return <BlockAlternatingFeatures data={block} key={key} />;
              case BlockMediaCollectionStyle.ALTERNATING_SMALL_IMAGES:
                return <BlockAlternatingSmallImages data={block} key={key} />;
              case BlockMediaCollectionStyle.SMALL_IMAGE_SIDE:
                return <ValuesAlternate data={block} key={key} />;
              case BlockMediaCollectionStyle.ROLE_CAROUSEL:
                return <BlockTeamCarousel data={block} key={key} />;
              case BlockMediaCollectionStyle.ANIMATED_FEATURES:
                return (
                  <BlockAlternatingFeaturesAnimated data={block} key={key} />
                );
              case BlockMediaCollectionStyle.TWO_COLUMNS_SIDE:
                return (
                  <MediaCollectionBlockTwoColumnsSide data={block} key={key} />
                );
              case BlockMediaCollectionStyle.ZOOM_CAROUSEL:
                return <BlockZoomCarousel data={block} key={key} />;
              case BlockMediaCollectionStyle.HORIZONTAL_SCROLL_CAROUSEL:
                return <BlockHorizontalScrollCarousel data={block} key={key} />;
              case BlockMediaCollectionStyle.INTEGRATIONS:
                return <BlockIntegrations data={block} key={key} />;
              default:
                return (
                  <div key={key}>
                    Can not find media collection block for style {block.style}
                  </div>
                );
            }
          case BlockType.BLOCK_CONTENT:
            switch (block.style) {
              case BlockContentStyle.TWO_COLUMN:
                return <ContentTwoColumn data={block} key={key} />;
              case BlockContentStyle.ONE_COLUMN:
                return <ContentOneColumn data={block} key={key} />;
              default:
                return (
                  <div key={key}>
                    Can not find content block for style {block.style}
                  </div>
                );
            }
          case BlockType.BLOCK_IMAGE:
            switch (block.style) {
              case BlockImageStyle.FULLSCREEN:
                return <BlockImageFullscreen data={block} key={key} />;
              case BlockImageStyle.LARGE_HEADING:
                return <BlockImageGridLargeHeading data={block} key={key} />;
              case BlockImageStyle.SIMPLE:
              default:
                return <BlockImageGrid data={block} key={key} />;
            }
          case BlockType.BLOCK_QUOTE:
            return <Quote data={block} key={key} />;
          case BlockType.BLOCK_JOB_OPENINGS:
            return <CareersOpenings data={block} jobs={jobs} key={key} />;
          case BlockType.BLOCK_CTA:
            switch (block.style) {
              case 'Dark':
                return <CtaDarkBlock data={block} key={key} />;
              case 'Image':
                return <CtaBlock data={block} key={key} />;
              case BlockImageStyle.SIMPLE:
              default:
                return <div>style not found</div>;
            }

          // Temporarily removed because the query reached its size limit.
          // These blocks are not used at the moment. TODO: split query in small
          case BlockType.BLOCK_SCROLLING_FEATURES:
            return <BlockScrollingFeatures data={block} key={key} />;
          case BlockType.BLOCK_TEXT_TYPEWRITER:
            return <BlockTextTypewriter data={block} key={key} />;
          case BlockType.BLOCK_LOCATIONS:
            return <BlockLocations data={block} key={key} />;
          case BlockType.BLOCK_WITH_WITHOUT_FLOW:
            return <BlockWithWithoutFlow data={block} key={key} />;
          case BlockType.BLOCK_STATS_COUNTER:
            return <BlockStatsCounter data={block} key={key} />;
          case BlockType.BLOCK_FAQ:
            return <BlockFaq data={block} key={key} />;
          case BlockType.BLOCK_LOGOS_AND_TESTIMONIALS:
            switch (block.style?.toLowerCase()) {
              case 'carousel':
                return <BlockTestimonialCarousel data={block} key={key} />;
              case 'cards':
              default:
                return <BlockLogoAndTestimonials data={block} key={key} />;
            }

          case BlockType.BLOCK_TABLE_COMPETITORS:
            return <BlockTableCompetitors data={block} key={key} />;
          case BlockType.BLOCK_PRE_FOOTER:
            return <BlockPreFooter data={block} key={key} />;
          case BlockType.BLOCK_PRICING:
            return <BlockPricing data={block} key={key} />;
          case BlockType.BLOCK_DEMO_FORM:
            return <BlockDemoForm data={block} key={key} />;
          case BlockType.BLOCK_AI:
            return <BlockAi data={block} key={key} />;
          default:
            return (
              <div key={blockType}>Could not render block {blockType}</div>
            );
        }
      })}
    </>
  );
}

export default memo(Blocks);
