import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import type { BlockLogoFragment } from '../schemas/api';

import Image from './Image';

import { Grid, columns } from '../styles/grid';

import LinkJSX from './Link';
import { spacing } from '../styles/blocks';
import COLORS from '../styles/colors';
import TYPO from '../styles/typography';

type ContainerProps = {
  $spacing: string;
};
const Container = styled(Grid)<ContainerProps>`
  ${(props) => spacing(props.$spacing || '')};
  margin-bottom: 48px;
`;

type LogosPros = { $span: number; $columns: number };

const LogoGrid = styled.div<LogosPros>`
  align-items: center;
  justify-content: center;

  ${columns(1, 12)}
  display: grid;
  grid-template-columns: repeat(4, [col-start] 1fr);

  background-color: ${COLORS.shades.s200.css};
  gap: 1px;
  @media (min-width: 640px) {
    ${columns(1, 12)}
    grid-template-columns: repeat(4, [col-start] 1fr);
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(6, [col-start] 1fr);
    ${(props) => {
      const start = props.$columns
        ? Math.floor((12 - props.$columns) / 2) + 1
        : 1;
      const span = props.$columns || 12;
      return columns(start, span);
    }};
  }
  column-gap: 1px;
`;

const CellStyle = css`
  position: relative;
  width: 100%;
  min-height: 60px;
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 4vw;

  background-color: var(--body-background);
  background-clip: padding-box;
`;

const Cell = styled.div`
  ${columns(null, 1)};
  ${CellStyle};

  img {
    opacity: 0.7;
  }
  &:hover img {
    opacity: 0.5;
  }

  @media (min-width: 640px) {
    height: 72px;
    padding: 12px 5vw;
  }
  @media (min-width: 768px) {
    height: 94px;
    padding: 12px min(2vw, 32px);
  }
`;

const CellHeader = styled.div`
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
  @media (min-width: 640px) {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 5;
  }
  @media (min-width: 768px) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 4;
  }

  ${CellStyle};

  color: ${COLORS.brand.regular.css};
  ${TYPO.strong};

  text-align: center;
  padding: 24px 48px;
  height: 100%;

  font-size: 21px;
  @media (min-width: 640px) {
    font-size: 28px;
  }
  @media (min-width: 768px) {
    font-size: 28px;
  }
  @media (min-width: 960px) {
    font-size: 32px;
  }
`;

type BlockLogoProps = {
  data: BlockLogoFragment;
};
function BlockLogo({ data }: BlockLogoProps) {
  const logos = data.logos?.items;

  return (
    <Container $spacing={data.spacing}>
      {Array.isArray(logos) && !!logos.length && (
        <LogoGrid
          $span={data.rowSpan || logos.length}
          $columns={data.columnSpan}
        >
          <CellHeader>{data.heading}</CellHeader>
          {logos.map((d) => {
            return (
              <LinkJSX link={{ type: 'external', url: d.url }} key={d.name}>
                <Cell>
                  <Image
                    src={d.logo.url}
                    width={d.logo.width}
                    height={d.logo.height}
                    alt={d.name}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </Cell>
              </LinkJSX>
            );
          })}
        </LogoGrid>
      )}
    </Container>
  );
}

export default memo(BlockLogo);
